import { Col, Collapse, Input, Row, Table} from 'antd';
import debounce from 'lodash/debounce';
import data from "./data";
import {useState} from "react";

const {Panel} = Collapse;
const { Search } = Input;

const columns = [
    {
        title: 'Produs',
        dataIndex: 'product',
        key: 'product',
        render: (item) => item.charAt(0).toUpperCase() + item.toLowerCase().slice(1)
    }
];
const App = () => {
    const [tableData, setTableData] = useState(data)
    const onSearch = (e) => {
        const val = e.target.value;
        if (val) {
            setTableData(data.filter((item) => item.product.toLowerCase().indexOf(val) !== -1));
        } else {
            setTableData(data);
        }
    }
    return <Row className={"container"}>
        <Col span={24}>
            <Input
                placeholder="Cauta produs"
                allowClear
                size="large"
                onChange={debounce(onSearch, 300)}
            />
            <Table
                pagination={false}
                columns={columns}
                expandable={{
                    expandedRowRender: (record) => (
                        <Collapse defaultActiveKey={['1']}>
                            <Panel header="Ingrediente" key="1">
                                {record.igredients.toLowerCase()}
                            </Panel>
                            {
                                record.alergens && <Panel header="Alergeni" key="2">
                                    <ul>
                                        {
                                            record.alergens.split(',').map((item, key) => (
                                                <li key={key}>{item}</li>
                                            ))
                                        }
                                    </ul>
                                </Panel>
                            }
                            <Panel header="Valori nutritionale" key="3">
                                <ul>
                                    <li>Proteine: <strong>{record.proteins}</strong></li>
                                    <li>Grasimi: <strong>{record.fat}</strong></li>
                                    <li>Glucide: <strong>{record.glucides}</strong></li>
                                    <li>KCal: <strong>{record.kcal}</strong></li>
                                    <li>KJ: <strong>{record.kj}</strong></li>
                                </ul>
                            </Panel>
                        </Collapse>
                    ),
                    rowExpandable: (record) => record,
                    expandRowByClick: true,
                }}
                dataSource={tableData}
                rowKey={"productCode"}
            /></Col>
    </Row>
};
export default App;